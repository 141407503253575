/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button, SeparateLine } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Chci hypotéku"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center pb--80 pt--80" style={{"backgroundColor":"#f5f8fd"}} name={"ocpl270ihkd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Chci hypotéku\n\n\n"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":1070,"marginTop":22}} content={"Získání hypotéky může být složitý proces, plný papírování a nejasností. S mými zkušenostmi v oblasti hypoték a financování nemovitostí vám mohu pomoci najít optimální řešení přizpůsobené vašim potřebám a finanční situaci. Společně probereme dostupné možnosti, abychom zajistili, že získáte nejlepší možné podmínky pro vaši hypotéku.\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"3l2zi8a56dj"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--bottom" style={{"paddingLeft":0}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":596}}>
              
              <Title className="title-box fs--30" content={"Proč hypotéku řešit se mnou ?\n \n\n\n"}>
              </Title>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"S osmiletou praxí v oblasti hypoték a financování nemovitostí jsem získal zkušenosti, které mi umožňují nabídnout vám odborné poradenství a podporu. Rozumím, že úspěšné získání hypotéky nezávisí pouze na výši úrokové sazby; klíčové jsou také podmínky úvěru, flexibilita a celková výhodnost nabídky pro vaši situaci.\n\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Díky mému působení v realitním sektoru nabízím komplexní služby spočívající nejen v navigaci hypotečním procesem, ale také v hodnocení a výběru nemovitostí. Můžu vám pomoci nejen s výběrem vhodné hypotéky, ale i s odhadem ceny vaší vysněné nemovitosti, což je kritický faktor při posuzování bankou.\n  \n\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Můj přístup se odlišuje individuálním poradenstvím, které přesahuje běžné služby hypotečního makléře. Spoluprací se mnou získáte nejen výhodné financování vašeho nového domova, ale také klidnou jistotu, že vaše rozhodnutí je informované a dobře promyšlené. Společně najdeme řešení přizpůsobené vašim potřebám a cílům, abychom zajistili, že proces získání hypotéky bude pro vás co nejpříjemnější a nejefektivnější.\n\n\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <ContactForm className="--shape3" action={"contact"} name={"9velcwwvqg"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"ndsbszx345p"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail","id":"hx6mo2bteq"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"mbhc4li3ha"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"uudb3rmixl8"},{"name":"Odeslat","type":"submit","id":"chakkgeyurq"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c4hlub css-c2cxkz --parallax pb--60 pt--60" name={"prefooter"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=3000x_.png);
    }
  
background-position: 50% 25%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow4 fs--48 w--600 lh--14 pb--06 pt--06" style={{"maxWidth":824}} content={"<span style=\"color: var(--color-custom-2);\">Stavíme mosty k vašim vysněným nemovitostem\n</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4" style={{"marginTop":0}} content={"Kontaktovat"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"k57myfoevo"}>
        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}